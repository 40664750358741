import { Grommet } from "grommet";
import { BrowserRouter } from "react-router-dom";

import { AppRoutes } from "./Routes";

function App() {
  const theme = {
    global: {
      font: {
        fontFamily: "Raleway, 'Helvetica Neue', Helvetica, Arial, sans-serif",
        size: "18px",
        height: "20px",
      },
    },
  };

  return (
    <BrowserRouter>
      <Grommet theme={theme} style={{ height: "100%" }}>
        <AppRoutes />
      </Grommet>
    </BrowserRouter>
  );
}

export default App;
