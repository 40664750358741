import Footer from "./Footer";
import Header from "./Header";

const ChalkPlant = () => {
  return (
    <>
      <Header />

      <section
        id="page-title"
        className="dark"
        style={{ background: "url(/images/pattern/pattern10.png)" }}
      >
        <div class="container">
          <div class="page-title">
            <h1>Community Projects</h1>
            <span>Projects in the community you need to know about.</span>
          </div>
        </div>
      </section>

      {/* <!-- Content --> */}
      <section id="page-content" className="p-b-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                {/* <div className="col-lg-12">
                  <div
                    className="carousel carousel-promotion m-b-40"
                    data-items="1"
                    data-margin="40"
                    data-loop="true"
                    data-autoplay="true"
                  >
                    <div className="portfolio-item img-zoom">
                      <div className="portfolio-item-wrap">
                        <div className="portfolio-image">
                          <a href="#">
                            <img
                              src="/images/portfolio/project/31.jpg"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="portfolio-description">
                          <a
                            title="Paper Pouch!"
                            data-lightbox="image"
                            href="/images/portfolio/project/31.jpg"
                            className="btn btn-light btn-roundeded"
                          >
                            Zoom
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="portfolio-item img-zoom">
                      <div className="portfolio-item-wrap">
                        <div className="portfolio-image">
                          <a href="#">
                            <img
                              src="/images/portfolio/project/32.jpg"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="portfolio-description">
                          <a
                            title="Paper Pouch!"
                            data-lightbox="image"
                            href="images/portfolio/project/32.jpg"
                            className="btn btn-light btn-roundeded"
                          >
                            Zoom
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="portfolio-item img-zoom">
                      <div className="portfolio-item-wrap">
                        <div className="portfolio-image">
                          <a href="#">
                            <img
                              src="/images/portfolio/project/33.jpg"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="portfolio-description">
                          <a
                            title="Paper Pouch!"
                            data-lightbox="image"
                            href="/images/portfolio/project/33.jpg"
                            className="btn btn-light btn-roundeded"
                          >
                            Zoom
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="portfolio-item img-zoom">
                      <div className="portfolio-item-wrap">
                        <div className="portfolio-image">
                          <a href="#">
                            <img
                              src="/images/portfolio/project/34.jpg"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="portfolio-description">
                          <a
                            title="Paper Pouch!"
                            data-lightbox="image"
                            href="/images/portfolio/project/34.jpg"
                            className="btn btn-light btn-roundeded"
                          >
                            Zoom
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="col-lg-12">
                  <div className="project-description text-center">
                    <h2>Chalk Plant</h2>
                    <p>
                      A community experience coming to Brooklyn powered by our
                      favorite climbers, explorers, adventurers, and artist.
                    </p>
                    <br />
                    <div style={{ marginBottom: "20px" }}>
                      <img
                        src="/images/chalkplant2.png"
                        className="img-fluid"
                        style={{ width: "500px" }}
                      />
                    </div>

                    <div style={{ margin: "0 auto" }} className="align-center">
                      <form
                        style={{ maxWidth: "25rem" }}
                        action="https://tinyletter.com/projectbk"
                        method="POST"
                        target="popupwindow"
                        onSubmit={() => {
                          window.open(
                            "https://tinyletter.com/projectbk",
                            "popupwindow",
                            "scrollbars=yes,width=800,height=600"
                          );
                          return true;
                        }}
                      >
                        <input type="hidden" value="1" name="embed" />
                        <div className="form-group">
                          <label for="tlemail">Join our Mailing List</label>
                          <input
                            name="email"
                            id="tlemail"
                            className="form-control"
                            aria-describedby="emailHelp"
                            placeholder="Enter email"
                            type="email"
                          />
                          <button type="submit" class="btn m-t-30 mt-3">
                            Subscribe
                          </button>
                        </div>
                      </form>
                    </div>
                    <hr />
                    <div className="portfolio-share">
                      <h4>Connect with this project</h4>
                      <div className="align-center">
                        <a
                          className="btn btn-slide btn-light"
                          href="https://twitter.com/chalkplant/"
                          data-width="120"
                        >
                          <i className="fab fa-twitter"></i>
                          <span>Twitter</span>
                        </a>
                        <a
                          className="btn btn-slide btn-light"
                          href="https://www.instagram.com/thechalkplant/"
                          data-width="140"
                        >
                          <i className="fab fa-instagram"></i>
                          <span>Instagram</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end: Content --> */}

      <Footer />
    </>
  );
};

export default ChalkPlant;
