const Header = () => {
  return (
    <>
      {/* <!-- Header --> */}
      <header
        id="header"
        data-transparent="true"
        className="dark submenu-light"
      >
        <div className="header-inner">
          <div className="container">
            {/* <!--Logo--> */}
            <div id="logo">
              <a href="/">
                <img
                  src="/images/boc_logo.png"
                  className="logo-default"
                  style={{ width: "150px", height: "auto", marginTop: "30px" }}
                />
                <img
                  src="/images/boc_logo.png"
                  className="logo-dark"
                  style={{ width: "150px", height: "auto", marginTop: "30px" }}
                />
              </a>
            </div>
            {/* <!--End: Logo--> */}
            {/* <!--Navigation Resposnive Trigger--> */}
            <div id="mainMenu-trigger">
              <a className="lines-button x">
                <span className="lines"></span>
              </a>
            </div>
            {/* <!--end: Navigation Resposnive Trigger--> */}
          </div>
        </div>
      </header>
      {/* <!-- end: Header --> */}
    </>
  );
};

export default Header;
