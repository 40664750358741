import Header from "./Header";
import Footer from "./Footer";

export const Home = () => {
  return (
    <>
      <Header />

      {/* <!-- SECTION IMAGE FULLSCREEN --> */}
      <section className="fullscreen background-overlay">
        <div
          className="youtube-background"
          data-youtube-url="https://www.youtube.com/watch?v=7LWq5s-s4pY"
          data-youtube-autoplay="true"
        />
        {/* <div class="container-fluid">
          <div class="container-fullscreen">
            <div class="text-middle text-center text-light">
              <h2 class="text-lg-x2 fw-800">YOUTUBE VIDEO</h2>
              <p class="lead">
                We are Creative Agency from Melburne, Australia
              </p>
              <a class="btn btn-light" href="#">
                Exoplore our World
              </a>
            </div>
          </div>
        </div> */}
      </section>
      {/* <!-- end: SECTION IMAGE FULLSCREEN --> */}

      <Footer />
    </>
  );
};
